import store from "../store";

export default [
  {
    command: "/opmode",
    params: {},
    name: "Modo Técnico",
    description: "Habilita e desabilita o modo técnico.",
    function: async () => {
      await new Promise((resolve) => setTimeout(resolve, 800));

      const { opMode } = store.state.auth.config;
      store.commit("auth/setConfig", { opMode: !opMode });

      return `Modo técnico ${opMode ? "des" : ""}ativado!`;
    },
  },
  {
    command: "/sudomode",
    params: {},
    name: "Modo Super",
    description: "Habilita e desabilita o modo super.",
    function: async () => {
      await new Promise((resolve) => setTimeout(resolve, 800));

      const { sudoMode } = store.state.auth.config;
      store.commit("auth/setConfig", { sudoMode: !sudoMode });

      return `Modo super ${sudoMode ? "des" : ""}ativado!`;
    },
  },
];
